import store from "@/base/store";
// import menu from "@/base/store/modules/menu";
// import home from "@/base/store/modules/home";
// import sale from "@/base/store/modules/sale";
// import product from "@/base/store/modules/product";
// import category from "@/base/store/modules/category";
// //import { isServer } from "@storefront/core/helpers";
// store.registerModule("menu", menu);
// store.registerModule("home", home);
// store.registerModule("sale", sale);
// store.registerModule("product", product);
// store.registerModule("category", category);
export default store;
